import React from "react";
import './Blog.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// images
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Blog({ FaqList, Blogs }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [getPathValue, setgetPathValue] = useState("");

    const [header, setHeader] = useState(false)
    const listenScrollEvent = () => {
        if (window.scrollY < 50 && window.screen.width < 690) {
            return setHeader(false)
        } else if (window.scrollY > 50 && window.screen.width < 690) {
            return setHeader(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);

    }, [window.scrollY]);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    // readingTime();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.category;
        setgetPathValue(params.category ? params.category : null)
        console.log(value);
        console.log(getPathValue);
    }, []);


    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());

    return (

        <div className="Blog">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Chimney Care Blog | Expert Tips & Advice from Anaheim Chimney Sweep</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Chimney Care Blog | Expert Tips & Advice from Anaheim Chimney Sweep" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Chimney Care Blog | Expert Tips & Advice from Anaheim Chimney Sweep" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />


                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweepanaheim.org/blog" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Anaheim Chimney Sweep Blog for expert advice, maintenance tips, and safety insights on chimney care. Stay informed and keep your chimney safe and efficient with our comprehensive articles." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Anaheim Chimney Sweep Blog for expert advice, maintenance tips, and safety insights on chimney care. Stay informed and keep your chimney safe and efficient with our comprehensive articles." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Chimney Care Blog | Expert Tips & Advice from Anaheim Chimney Sweep" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="BlogHeader">
                <div className="BlogHeaderTop">
                    <h1>Stay Informed & Up-to-Date</h1>
                    <h3>
                    Living in Anaheim, CA, offers the charm of beautiful weather and warm, cozy evenings by the fireplace. To ensure your fireplace remains safe and efficient, regular chimney sweeping is crucial. Discover why chimney maintenance should be a top priority for every Anaheim homeowner.
                    </h3>
                    <a href="tel:951-499-5984">CALL 951-499-5984<img src={telephone} alt="Chimney Sweep In Anaheim, CA | Chimney Repair In Anaheim, CA | Chimney Services In Anaheim, CA | Chimney Inspection In Anaheim, CA" /></a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Chimney Sweep In Anaheim, CA | Chimney Repair In Anaheim, CA | Chimney Services In Anaheim, CA | Chimney Inspection In Anaheim, CA" /></a>

                </div>
                <div className="BlogHeaderBottom">
                    <h1>Blogs</h1>
                </div>
            </div>
            <div className="BlogsList">
                {Blogs.filter(a => getPathValue ? a.CategoryPath == getPathValue : a.CategoryPath != getPathValue).reverse().map((A, index) =>
                    <a href={`/blog/${A.BlogPath.replace(/ /g, "_").toLowerCase() + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListBox" >
                        <article>
                            <div className="BlogsListBoxLeft">
                                <img src={A.Photos} alt={`${A.BlogPath} | Anaheim Chimney Sweep | SENEX | Business | Busniess Laon | ondeck`} />
                            </div>
                            <div className="BlogsListBoxRight">
                                <h1>{A.Title}</h1>
                                <h2>{A.Discription.slice(0, 60 - 1) + "..."}</h2>
                                <span>
                                    <h5>{readingTime(Blogs[index])} MIN READ</h5>
                                </span>
                            </div>
                        </article>

                    </a>
                )}
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>

            <NavigatorPath />

        </div >
    )
}

