import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Anaheim Chimney Sweep | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Anaheim Chimney Sweep | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Anaheim Chimney Sweep | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweepanaheim.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Chimney sweeping, Chimney cleaning, Chimney inspection, Chimney repair, Fireplace maintenance, Creosote removal, Chimney safety, Chimney flue cleaning, Soot removal, Chimney maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Anaheim Chimney Sweep | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Chimney Sweep Appointment Online In Anaheim, California</a>
                    <a href="/services">Our Services</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>
                    <a href='/reviews'>Customer Reviews</a>

                    {/* Service */}
                    {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox">
                            {A.Title}
                        </a>
                    )}

                    {/* Reviews */}
                    <h1>Locations</h1>
                    {LocationData.map((A, index) =>
                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >{A.City} Garage Door</a>
                    )}

                    {/* Blog */}
                    <a href="/blog/chimney_sweep_services_in_Anaheim,_california:_ensuring_safety_and_efficiency_with_Anaheim_chimney_sweep_3">Comprehensive Guide to Chimney Inspection in Anaheim, California: How Anaheim Chimney Sweep Can Assist You</a>
                    <a href="/blog/the_essential_guide_to_chimney_cleaning_in_Anaheim,_california:_how_Anaheim_chimney_sweep_can_help_keep_your_home_safe_2">Comprehensive Guide to Chimney Repair in Anaheim, California: How Anaheim Chimney Sweep Can Assist You</a>
                    <a href="/blog/comprehensive_guide_to_chimney_repair_in_Anaheim,_california:_how_Anaheim_chimney_sweep_can_assist_you_1">The Essential Guide to Chimney Cleaning in Anaheim, California: How Anaheim Chimney Sweep Can Help Keep You</a>
                    <a href="/blog/comprehensive_guide_to_chimney_inspection_in_Anaheim,_california:_how_Anaheim_chimney_sweep_can_assist_you_0">Chimney Sweep Services in Anaheim, California: Ensuring Safety and Efficiency with Anaheim Chimney Sweep</a>
                    {/* Faq */}

                    <a href="/faq/How_often_should_I_have_my_chimney_cleaned_Anaheim_CA_0">How often should I have my chimney cleaned Anaheim CA</a>
                    <a href="/faq/What_are_the_signs_that_my_chimney_needs_cleaning_Anaheim_CA_1">What are the signs that my chimney needs cleaning Anaheim CA</a>
                    <a href="/faq/Can_I_clean_my_chimney_myself_Anaheim_CA_2">Can I clean my chimney myself Anaheim CA</a>
                    <a href="/faq/What_is_creosote,_and_why_is_it_dangerous_Anaheim_CA_3">What is creosote, and why is it dangerous Anaheim CA</a>
                    <a href="/faq/Does_chimney_cleaning_prevent_chimney_fires_Anaheim_CA_4">Does chimney cleaning prevent chimney fires Anaheim CA</a>
                    <a href="/faq/What_is_the_purpose_of_a_chimney_inspection_Anaheim_CA_5">What is the purpose of a chimney inspection Anaheim CA</a>
                    <a href="/faq/How_often_should_I_have_a_chimney_inspection_Anaheim_CA_6">How often should I have a chimney inspection Anaheim CA</a>
                    <a href="/faq/What’s_the_difference_between_a_Level_1,_2,_and_3_chimney_inspection_Anaheim_CA_7">What’s the difference between a Level 1, 2, and 3 chimney inspection Anaheim CA</a>
                    <a href="/faq/What_should_I_do_if_my_chimney_fails_inspection_Anaheim_CA_8">What should I do if my chimney fails inspection Anaheim CA</a>
                    <a href="/faq/Can_a_chimney_inspection_detect_carbon_monoxide_issues_Anaheim_CA_9">Can a chimney inspection detect carbon monoxide issues Anaheim CA</a>
                    <a href="/faq/What_are_common_chimney_repairs_Anaheim_CA_10">What are common chimney repairs Anaheim CA</a>
                    <a href="/faq/How_do_I_know_if_my_chimney_needs_repair_Anaheim_CA_11">How do I know if my chimney needs repair Anaheim CA</a>
                    <a href="/faq/How_much_do_chimney_repairs_typically_cost_Anaheim_CA_12">How much do chimney repairs typically cost Anaheim CA</a>
                    <a href="/faq/How_long_does_chimney_repair_take_Anaheim_CA_13">How long does chimney repair take Anaheim CA</a>
                    <a href="/faq/Can_chimney_repairs_be_done_year-round_Anaheim_CA_14">Can chimney repairs be done year-round Anaheim CA</a>
                    <a href="/faq/What_does_a_chimney_sweep_do_Anaheim_CA_15">What does a chimney sweep do Anaheim CA</a>
                    <a href="/faq/How_often_should_I_schedule_a_chimney_sweep_Anaheim_CA_16">How often should I schedule a chimney sweep Anaheim CA</a>
                    <a href="/faq/What’s_the_difference_between_a_chimney_sweep_and_a_chimney_cleaning_Anaheim_CA_17">What’s the difference between a chimney sweep and a chimney cleaning Anaheim CA</a>
                    <a href="/faq/How_can_I_prepare_for_a_chimney_sweep_visit_Anaheim_CA_18">How can I prepare for a chimney sweep visit Anaheim CA</a>
                    <a href="/faq/Is_a_chimney_sweep_messy_Anaheim_CA_19">Is a chimney sweep messy Anaheim CA</a>
                    <a href="/faq/What_is_a_chimney_liner_Anaheim_CA_20">What is a chimney liner Anaheim CA</a>
                    <a href="/faq/Why_might_I_need_a_new_chimney_liner_Anaheim_CA_21">Why might I need a new chimney liner Anaheim CA</a>
                    <a href="/faq/How_long_does_chimney_liner_installation_take_Anaheim_CA_22">How long does chimney liner installation take Anaheim CA</a>
                    <a href="/faq/What_types_of_chimney_liners_are_available_Anaheim_CA_23">What types of chimney liners are available Anaheim CA</a>
                    <a href="/faq/How_much_does_chimney_liner_installation_cost_Anaheim_CA_24">How much does chimney liner installation cost Anaheim CA</a>
                    <a href="/faq/What_is_a_chimney_crown_Anaheim_CA_25">What is a chimney crown Anaheim CA</a>
                    <a href="/faq/How_do_I_know_if_my_chimney_crown_needs_repair_Anaheim_CA_26">How do I know if my chimney crown needs repair Anaheim CA</a>
                    <a href="/faq/What_happens_if_I_don’t_repair_a_damaged_chimney_crown_Anaheim_CA_27">What happens if I don’t repair a damaged chimney crown Anaheim CA</a>
                    <a href="/faq/How_long_does_chimney_crown_repair_take_Anaheim_CA_28">How long does chimney crown repair take Anaheim CA</a>
                    <a href="/faq/Can_a_chimney_crown_be_replaced_Anaheim_CA_29">Can a chimney crown be replaced Anaheim CA</a>
                    <a href="/faq/What_causes_chimney_draft_issues_Anaheim_CA_30">What causes chimney draft issues Anaheim CA</a>
                    <a href="/faq/How_can_I_tell_if_I_have_a_chimney_draft_problem_Anaheim_CA_31">How can I tell if I have a chimney draft problem Anaheim CA</a>
                    <a href="/faq/Can_a_chimney_cap_help_with_draft_issues_Anaheim_CA_32">Can a chimney cap help with draft issues Anaheim CA</a>
                    <a href="/faq/How_can_chimney_draft_issues_be_fixed_Anaheim_CA_33">How can chimney draft issues be fixed Anaheim CA</a>
                    <a href="/faq/Are_draft_issues_more_common_in_older_homes_Anaheim_CA_34">Are draft issues more common in older homes Anaheim CA</a>

                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
